<template>
  <div class="flex">
    <el-form ref="elForm" class="main-form" :model="formData" :rules="rules" size="medium" label-width="100px">
      <el-form-item label="院校名称" prop="Name">
        <el-input v-model="formData.Name" placeholder="请输入院校名称" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="院校代码" prop="Name">
        <el-input v-model="formData.Name" placeholder="请输入院校代码" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="Phone">
        <el-input v-model="formData.Phone" placeholder="请输入联系人" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="Phone">
        <el-input v-model="formData.Phone" placeholder="请输入手机号" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="Code">
        <el-input placeholder="请输入验证码" v-model="formData.Code" class="input-with-select">
          <el-button slot="append">获取验证码</el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="用户名" prop="Phone">
        <el-input v-model="formData.Phone" placeholder="未输入则默认手机号为院校管理员账号" clearable :style="{width: '100%'}">
        </el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="Password">
        <el-input v-model="formData.Password" placeholder="请输入登录密码" clearable show-password :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item label="重复密码" prop="RepeatPassword">
        <el-input v-model="formData.RepeatPassword" placeholder="请再次输入登录密码" clearable show-password :style="{width: '100%'}"></el-input>
      </el-form-item>
      <el-form-item size="large">
        <el-button type="primary" @click="submitForm">注册</el-button>
      </el-form-item>
    </el-form>
    <div class="form-right">
      <div class="right-qrcode">
        我是二维码
      </div>
      <p>微信扫码登录</p>
      <p>已有用户<el-button type="primary" plain size="small">直接登录</el-button>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        Name:'',
        Phone: '',
        Password: '',
        Code: '',
        RepeatPassword: '',
      },
      rules: {
        Name: [{
          required: true,
          message: '请输入学校名称',
          trigger: 'blur'
        }],
        Phone: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        Code: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
        Password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        RepeatPassword: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
      },
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() { },
  methods: {
    submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style lang="scss" scoped>
.main-form {
  width: 500px;
  padding: 20px;
  border-right: 1px dashed #dddddd;
}
.form-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  .right-qrcode {
    display: flex;
    background-color: #999999;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
  }
}
</style>
