<template>
  <div class="register-container">
    <div class="g-section">
      <div class="register-top">
        <logo />
      </div>
    </div>
    <div class="g-section">
      <div class="register-bottom">
        <div class="register-type w100">
          <el-tabs class="w100" v-model="activeName" >
            <el-tab-pane label="用户注册" name="apprentice">
              <apprentice/>
            </el-tab-pane>
            <el-tab-pane label="企业注册" name="enterprise">
              <enterprise/>
            </el-tab-pane>
            <el-tab-pane label="学校注册" name="school">
              <school/>
            </el-tab-pane>
            <el-tab-pane label="服务机构注册" name="agency">
              <enterprise/>
            </el-tab-pane>
          </el-tabs>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Common/Logo"
import Apprentice from "./components/Apprentice"
import Enterprise from "./components/Enterprise"
import School from "./components/School"
export default {
  components: { Logo ,Apprentice,Enterprise,School},
  data() {
    return {
      activeName: "apprentice"
    }
  }

}
</script>

<style lang="scss" scoped>
.register-container {
  display: flex;
  flex: 1;
  background-color: #c7edcc;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
  // background-image: url('../../assets/images/bg_register.jpg');
  // background-size: 100% 100%;
}
.register-top {
  display: flex;
  height: 120px;
  align-items: flex-end;
  padding-bottom: 10px;
  width: 800px;
}
.register-bottom {
  display: flex;
  border-radius: 6px;
  background-color: #ffffff;
  min-height: 300px;
  width: 800px;
}
.register-type {
  display: flex;
  justify-content: center;
  padding: 20px;
}
</style>